import React, { Component } from 'react';
import './App.css';
import RedditLogin from './components/RedditLogin';
import Main from './components/Main';
import About from './components/About';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-132839204-1');
ReactGA.pageview('/');

class App extends Component {
  render() {
    console.log('check', `${process.env.REACT_APP_REDDIT_REDIRECT_URI}`);
    return (
      <Router>
        <div className='App'>
          <Route exact path='/' component={Main} />
          <Route path='/reddit' component={RedditLogin} />
          <Route path='/about' component={About} />
        </div>
      </Router>
    );
  }
}

export default App;
